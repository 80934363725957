.random-program-container {
  background-color: white;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  max-height: 700px;
  max-width: 768px;
  overflow-y: auto;
}

.random-program-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.random-program-title {
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
  color: #2c3e50;
}

@media screen and (max-width: 700px) {
  .random-program-container {
    max-height: 500px;
  }
}
