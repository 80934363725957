.video {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-bottom: 1px solid var(--light-gray);
  padding: 10px 0;
  gap: 10px;
}

.video-details {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

.video-details:hover {
  cursor: pointer;
}

.video-title {
  max-width: 60%;
}

.video-share {
  fill: var(--primary-gray);
}

.video-thumbnail {
  border-radius: 8px;
  overflow: hidden;
}

@media (max-width: 375px) {
  .video-title {
    max-width: 50%;
  }
}
