.selected-video-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selected-video-container > .lds-ellipsis {
  position: absolute;
}

.selected-video-container > .lds-ellipsis div {
  background: white;
}

.selected-video {
  margin: 20px 0;
  border-radius: 4px;
  overflow: hidden;
}
